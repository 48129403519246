module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"undefined","includeInDevelopment":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby WooCommerce Theme","short_name":"Electra","start_url":"/","background_color":"#eaeaea","theme_color":"#1e1e1e","display":"standalone","icon":"/opt/build/repo/packages/gatsby-woocommerce-theme/src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"14736aa638a6ea44d0fbd566e9c6c8d6"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../packages/gatsby-woocommerce-theme/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://sitewanted.ru","gatsbySiteUrl":"https://sitewanted.ru","googleTagManagerId":"undefined","fbAppId":"undefined"},
    }]
