import React, { useState } from "react";

const defaultState = {
  dark: false,
  toggleLang: () => {}
};

const ThemeContext = React.createContext(defaultState);

const ThemeProvider = ({ children }) => {
  const [lang, setLang] = useState("ru");
  const toggleLang = () => {
    setLang(lang === "en" ? "ru" : "en");
  };

  return (
    <ThemeContext.Provider
      value={{
        lang,
        toggleLang
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
export { ThemeProvider };
